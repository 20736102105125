export const PROJECT_NAME = 'Envy Lounge Admin Panel';

export const BASE_URL = 'https://api.envyloungeclub.com';

export const WEBSITE_URL = 'https://admin.envyloungeclub.com';

export const api_url = BASE_URL + '/api/v1/';

export const api_url_admin = BASE_URL + '/api/v1/dashboard/admin/';

export const api_url_admin_dashboard = BASE_URL + '/api/v1/dashboard/';

export const IMG_URL = BASE_URL + '/storage/images/';

export const MAP_API_KEY = 'AIzaSyC4-agAT04hg-gmpsfMi89cKLblzpOtrl0';

export const export_url = BASE_URL + '/storage/';

export const example = BASE_URL + '/';

export const defaultCenter = { lat: 27.5041926, lng: -89.0941794 };

export const VAPID_KEY =
  'BC9W8pU4YZIXvdbtjKf-pgtqu9lU3XKO6NFPcqul28Nfht5ccDwq7Ucdz3ZB98NSaazUY4of5mphxB8lFrBLvAo';

export const API_KEY = 'AIzaSyC4-agAT04hg-gmpsfMi89cKLblzpOtrl0';

export const AUTH_DOMAIN = 'envy-lounge.firebaseapp.com';

export const PROJECT_ID = 'envy-lounge';

export const STORAGE_BUCKET = 'envy-lounge.appspot.com';

export const MESSAGING_SENDER_ID = '37449879990';

export const APP_ID = '1:37449879990:web:5f45a5f4abfd6effe43117';

export const MEASUREMENT_ID = 'G-9FR1DPVEBT';

export const RECAPTCHASITEKEY = '6LcffKEpAAAAAAIIdWuwhFt_ynudDTDadYdk3T01';

export const RECAPTCHASECRETKEY = '6LcffKEpAAAAAC5EgPkRKIjOhD_Hh9VpyP9XwwMZ';

export const DEMO_SELLER = 107; // seller_id

export const DEMO_SHOP = 501; // seller_id

export const DEMO_DELIVERYMAN = 106; // deliveryman_id

export const DEMO_MANEGER = 114; // maneger_id

export const DEMO_MODERATOR = 297; // moderator_id

export const DEMO_ADMIN = 501; // administrator_id

export const DEMO_WAITER = 108; // waiter_id

export const SUPPORTED_FORMATS = [
  'image/jpg',

  'image/jpeg',

  'image/png',

  'image/svg+xml',

  'image/svg',
];
